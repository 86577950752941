@import  'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@charset "UTF-8";
/*
	Apex by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/
@import "libs/skel";
@include skel-breakpoints(( desktop: '(min-width: 737px)', tablet: '(min-width: 737px) and (max-width: 1200px)', mobile: '(max-width: 736px)' ));
@include skel-layout(( reset: 'full', boxModel: 'border', grid: true, conditionals: true, containers: true, breakpoints: ( desktop: ( containers: 1200px, grid: ( gutters: (25px, 25px) ), ), tablet: ( containers: 1000px, ), mobile: ( containers: (100%, true), grid: ( gutters: (15px, 15px) ), ) ) ));
// Custom
#page-wrapper {
  	display: flex;
  	min-height: 100vh;
  	flex-direction: column;
}
#main-wrapper {
	flex: 1;
}
// resize the banner images from blog pages to blog list to column size
div.row.blogList img {
	max-width: 100%;
	height: auto;
}
// quotations
blockquote {
font-family: Georgia, serif;
// font-size: 18px;
font-style: italic;
line-height: 1.45;
position: relative;
color: #383838;
}
blockquote:before {
// display: block;
content: "\201C";
font-size: 30px;
position: relative;
// left: -5px;
// top: -5px;
color: #7a7a7a;
}
blockquote cite {
color: #999999;
// font-size: 14px;
display: block;
margin-top: 5px;
}
 
blockquote cite:before {
content: "\2014 \2009";
}


/* Basic */
body,
input,
textarea {
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
}
body {
	background: #fff;
	color: #474747;
	font-size: 10pt;
	line-height: 1.75em;
}
a {
	color: #7ABAA4;
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 300;
	color: #373737;
}
h3 {
	font-weight: 400;
	margin: 0 0 0.5em;
}
p,
ul {
	margin-bottom: 3em;
}
form {
	input,
	select {
		-webkit-appearance: none;
		border-radius: 0;
	}
	input {
		@include vendor( 'box-sizing', 'border-box');
		border: solid 1px #d9d9d9;
		padding: 0.5em 1em;
		&.button {
			cursor: pointer;
		}
	}
}
.grid-spaced > .row {
	margin-bottom: 2em;
	&:last-child {
		margin-bottom: 0;
	}
}
.date {
	font-style: italic;
	color: #a0a0a0;
}
.image {
	display: inline-block;
	border: solid 1px #d9d9d9;
	padding: 3px;
	img {
		display: block;
	}
}
.image-fit {
	display: block;
	margin: 0 0 2em;
	img {
		width: 100%;
	}
}
.align-left {
	float: left;
	margin-right: 2em;
}
.blocks {
	a {
		@include vendor( 'transition', 'background-color .25s ease-in-out');
		position: relative;
		display: block;
		color: #fff;
		text-decoration: none;
		padding: 3em 7em 3em 2em;
		margin: 0 0 0.5em;
		outline: 0;
		&:after {
			content: "\f0a9";
			font-family: FontAwesome;
			text-decoration: none;
			font-size: 55px;
			font-style: normal;
			font-weight: normal;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			display: block;
			width: 48px;
			height: 48px;
			position: absolute;
		}
	}
	p {
		font-size: 1.25em;
		margin: 0;
		color: rgba(255, 255, 255, 0.8);
	}
	h2 {
		color: #fff;
		font-size: 2.25em;
		letter-spacing: -1px;
		margin: 0 0 0.5em;
		line-height: 1em;
	}
	.one {
		background: #7ABAA4;
		&:hover {
			background: #8ACAB4;
		}
	}
	.two {
		background: #007C9E;
		&:hover {
			background: #108CAE;
		}
	}
	.three {
		background: #2f2f2f url('images/overlay.png');
		&:hover {
			background: #3f3f3f;
		}
	}
}
ul {
	&.style1 {
		li {
			border-top: solid 1px #d9d9d9;
			padding: 1.5em 0 0;
			margin: 1.5em 0 0;
			&:after {
				content: '';
				display: block;
				clear: both;
			}
			&:first-child {
				padding-top: 0;
				border-top: 0;
			}
		}
		p {
			margin: 0 0 0.5em;
		}
	}
	&.style2 {
		li {
			border-top: solid 1px #d9d9d9;
			padding: 1em 0 0;
			margin: 1em 0 0;
			&:after {
				content: '';
				display: block;
				clear: both;
			}
			&:first-child {
				padding-top: 0;
				border-top: 0;
			}
		}
	}
	&.posts {
		h3 {
			margin: 0.25em 0;
		}
		p {
			margin-bottom: 0.1em;
		}
	}
	&.contact {
		li {
			position: relative;
			margin: 0 0 2.1em;
			padding: 0 0 0 60px;
			&:before {
				font-family: FontAwesome;
				font-size: 1.25em;
				text-decoration: none;
				font-style: normal;
				font-weight: normal;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				width: 32px;
				height: 32px;
				background: #676767;
				color: #fff;
				line-height: 32px;
				text-align: center;
			}
			&:after {
				content: '';
				display: block;
				clear: both;
			}
		}
	}
}
.button {
	@include vendor( 'transition', 'background-color .25s ease-in-out');
	position: relative;
	display: inline-block;
	background: #7ABAA4;
	color: #fff;
	padding: 1.2em 3.25em 1.2em 1.75em;
	text-decoration: none;
	font-size: 1.1em;
	outline: 0;
	&:hover {
		background: #8ACAB4;
	}
	&:before {
		content: '';
		display: block;
		position: absolute;
		right: 1.75em;
		bottom: 1.6em;
		border-left: solid 5px #fff;
		border-top: solid 5px transparent;
		border-bottom: solid 5px transparent;
	}
}
.button-alt {
	background: #2f2f2f url('images/overlay.png');
	&:hover {
		background: #3f3f3f;
	}
}
/* Icons */
.icon {
	text-decoration: none;
	&:before {
		display: inline-block;
		font-family: FontAwesome;
		font-size: 1.25em;
		text-decoration: none;
		font-style: normal;
		font-weight: normal;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}
/* Desktop */
@include breakpoint(desktop) {
	// custom
	// 1clarge need to be down scaled
	.image-style1 {
		img{
			width: 282px;
			height: 242px;
		}	
	}
	/* Basic */
	h2 {
		font-size: 2.25em;
		letter-spacing: -2px;
		margin: 0 0 1.5em;
	}
	h3 {
		font-size: 1em;
	}
	.right-sidebar,
	.side-padded {
		padding-left: 2em;
	}
	.left-sidebar {
		padding-right: 2em;
	}
	article > :last-child,
	section > :last-child {
		margin-bottom: 0;
	}
	.blocks a:after {
		right: 25px;
		bottom: 40px;
	}
	/* Wrappers */
	#header-wrapper {
		background: #7ABAA4;
		padding: 2em 0;
	}
	#banner-wrapper {
		position: relative;
		background: #2f2f2f url('images/overlay.png');
		padding: 4em 0;
		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: url('images/highlight.png') bottom center no-repeat;
			background-size: 1800px 100%;
		}
	}
	#search-wrapper {
		background: #f6f6f6;
		border-bottom: solid 1px #d9d9d9;
		padding: 2.5em 0;
	}
	#main-wrapper {
		padding: 6em 0;
	}
	#footer-wrapper {
		background: #f6f6f6;
		border-top: solid 1px #d9d9d9;
		padding: 5em 0;
	}
	/* Header */
	#page-header {
		color: #fff;
		position: relative;
		sub {
			font-size: 0.6em;
			color: #BCDCD1;
			letter-spacing: 1px;
		}
		h1 {
			font-size: 2em;
			letter-spacing: -2px;
			a {
				text-decoration: none;
			}
			span {
				font-weight: 400;
			}
		}
		a {
			color: #fff;
		}
		nav {
			position: absolute;
			right: 0;
			top: 0;
			ul li {
				display: inline-block;
				margin-left: 1.5em;
				font-size: 1.1em;
				a {
					@include vendor( 'transition', 'background-color .25s ease-in-out');
					outline: 0;
					position: relative;
					text-decoration: none;
					padding: 0.5em 1em;
				}
				&.active a,
				&:hover a {
					background: rgba(0, 0, 0, 0.15);
				}
				.arrow {
					padding-right: 2.5em;
					&:after {
						position: absolute;
						display: inline-block;
						content: '';
						border-top: solid 5px #fff;
						border-left: solid 5px transparent;
						border-right: solid 5px transparent;
						top: 1.15em;
						right: 1em;
					}
				}
			}
			> ul > li > ul {
				display: none;
			}
		}
	}
	.dropotron {
		background: #7ABAA4;
		background: rgba(0, 166, 235, 0.9);
		padding: 1em 0.5em;
		line-height: 3em;
		box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.15);
		a,
		span {
			@include vendor( 'transition', 'background-color .25s ease-in-out');
			position: relative;
			display: block;
			text-decoration: none;
			color: #fff;
			font-size: 1.1em;
			padding: 0 3em 0 1em;
		}
		.arrow:before {
			content: '';
			display: block;
			position: absolute;
			right: 1em;
			bottom: 1em;
			border-left: solid 5px #fff;
			border-top: solid 5px transparent;
			border-bottom: solid 5px transparent;
		}
		li:hover {
			 > span,
			> a {
				background: rgba(0, 0, 0, 0.15);
			}
		}
		&.level-0 {
			margin-top: 1.9em;
		}
	}
	/* Banner */
	#banner {
		position: relative;
		width: 100%;
		overflow: hidden;
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.35);
		height: 440px;
		.nav-next,
		.nav-previous {
			@include vendor( 'transition', 'background-color .25s ease-in-out');
			position: absolute;
			width: 52px;
			height: 64px;
			z-index: 3;
			opacity: 0;
			cursor: pointer;
			text-align: center;
			line-height: 64px;
			&:before {
				font-family: FontAwesome;
				text-decoration: none;
				font-size: 72px;
				font-style: normal;
				font-weight: normal;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				color: #fff;
			}
			&:hover {
				opacity: 1.0;
			}
		}
		&:hover {
			.nav-next,
			.nav-previous {
				opacity: 0.45;
			}
		}
		.nav-next {
			bottom: 20px;
			right: 20px;
			&:before {
				content: "\f105";
			}
		}
		.nav-previous {
			bottom: 20px;
			right: 72px;
			&:before {
				content: "\f104";
			}
		}
		.captions {
			position: absolute;
			background: #7ABAA4;
			background: rgba(0, 55, 78, 0.7);
			padding: 0em 7em 2em 1.5em;
			bottom: 1.5em;
			left: 1.5em;
			color: #fff;
			width: 50%;
			z-index: 1;
			&:after {
				content: "\f0a9";
				font-family: FontAwesome;
				text-decoration: none;
				font-size: 55px;
				font-style: normal;
				font-weight: normal;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				cursor: default;
				display: block;
				width: 48px;
				height: 48px;
				position: absolute;
				right: 30px;
				bottom: 40px;
			}
			.caption-line-1 {
				display: block;
				font-size: 3em;
				letter-spacing: -3px;
				margin: 0 0 0.75em;
			}
			.caption-line-2 {
				display: block;
				font-size: 1.5em;
				letter-spacing: -1px;
				line-height: 1.5em;
				color: rgba(255, 255, 255, 0.8);
			}
		}
		.viewer {
			background: #fff;
		}
		.slide {
			.caption-1,
			.caption-2 {
				display: none;
			}
			img {
				display: block;
				width: 1200px;
			}
			.link {
				position: absolute;
				text-indent: -9999px;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
			}
		}
	}
	/* Search */
	#search {
		position: relative;
		span {
			position: absolute;
			left: 0;
			bottom: 0.5em;
		}
		form {
			overflow: hidden;
			position: relative;
			input {
				&.text {
					display: block;
					width: 100%;
					height: 34px;
				}
				&.button {
					width: 34px;
					height: 34px;
					position: absolute;
					right: 0;
					top: 0;
					display: block;
					z-index: 1;
					border: 0;
					padding: 0;
					opacity: 0;
				}
			}
			&:before {
				content: "\f002";
				font-family: FontAwesome;
				text-decoration: none;
				font-size: 18px;
				font-style: normal;
				font-weight: normal;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 2;
				background: #7ABAA4;
				color: #fff;
				width: 34px;
				height: 34px;
				line-height: 34px;
				text-align: center;
			}
		}
	}
	/* Content */
	#content {
		 > article,
		> section {
			margin-bottom: 7.5em;
			&:last-child {
				margin-bottom: 0;
			}
		}
		article,
		section {
			header {
				border-top: solid 7px #7ABAA4;
				padding-top: 4em;
				margin: 0 0 3em;
				.byline {
					display: block;
					color: #a0a0a0;
					font-size: 1.25em;
				}
				h2 {
					margin: 0 0 0.5em;
				}
				&.blank-header {
					padding-top: 1em;
				}
			}
			&.blank-header{
				margin-bottom: 3em;
			}
			&.featured {
				header {
					.byline {
						font-size: 2em;
						letter-spacing: -2px;
					}
					h2 {
						font-size: 4em;
						letter-spacing: -3px;
						margin: 0.25em 0 0.75em;
					}
				}
				h3 {
					font-size: 1.5em;
					letter-spacing: -1px;
					margin: 0 0 1em;
					font-weight: 200;
				}
			}
		}
	}
	/* Sidebar */
	#sidebar {
		h2 {
			font-size: 1.75em;
			letter-spacing: -1px;
		}
		> section {
			margin-bottom: 5em;
			&:last-child {
				margin-bottom: 0;
			}
		}
		section {
			header {
				border-top: solid 7px #7ABAA4;
				padding-top: 3.5em;
				margin: 0 0 3em;
				h2 {
					margin: 0 0 0.5em;
				}
			}
			&.featured header h2 {
				font-size: 4em;
				letter-spacing: -3px;
				margin: 0.25em 0 0.75em;
			}
		}
	}
	/* Footer */
	#page-footer {
		h2 {
			font-size: 1.75em;
			letter-spacing: -1px;
		}
		section {
			margin: 0 0 5em;
			h2 {
				margin: 0 0 1.5em;
			}
		}
		> section:last-child {
			margin-bottom: 0;
		}
		a {
			color: #474747;
		}
	}
	/* Copyright */
	#copyright {
		border-top: solid 1px #d9d9d9;
		text-align: center;
		padding: 5em 0 6em;
		color: #a0a0a0;
	}
}
/* Tablet */
@include breakpoint(tablet) {
	/* Basic */
	body {
		min-width: 1000px;
		font-size: 9.5pt;
	}
	.blocks {
		a {
			padding: 2em 7em 2em 2em;
			margin: 0 0 0.5em;
			&:after {
				right: 20px;
				bottom: 25px;
			}
		}
		p {
			font-size: 1em;
			margin: 0;
		}
		h2 {
			font-size: 2em;
			margin: 0 0 0.5em;
		}
	}
	/* Wrappers */
	#header-wrapper {
		padding: 1em 0;
	}
	#banner-wrapper {
		padding: 2em 0;
		&:before {
			background-size: 1400px 100%;
		}
	}
	#search-wrapper {
		padding: 1.5em 0;
	}
	#main-wrapper {
		padding: 3em 0 4em;
	}
	#footer-wrapper {
		padding: 4em 0 3em;
	}
	/* Header */
	#page-header {
		h1 {
			font-size: 1.5em;
			letter-spacing: -1px;
		}
		nav ul li {
			margin-left: 0.5em;
		}
	}
	.dropotron.level-0 {
		margin-top: 1em;
	}
	/* Banner */
	#banner {
		height: 352px;
		.nav-next {
			right: 10px;
			bottom: 30px;
		}
		.nav-previous {
			right: 62px;
			bottom: 30px;
		}
		.captions {
			padding: 3em 7em 2em 2em;
			bottom: 1.25em;
			left: 1.25em;
			color: #fff;
			&:after {
				right: 20px;
				bottom: 25px;
			}
			.caption-line-1 {
				font-size: 2.5em;
				letter-spacing: -2px;
			}
			.caption-line-2 {
				font-size: 1.25em;
				letter-spacing: -1px;
				line-height: 1.25em;
			}
		}
		.slide img {
			width: 1001px;
		}
	}
	/* Content */
	#content {
		 > article,
		> section {
			margin-bottom: 4em;
		}
	}
	/* Sidebar */
	#sidebar > section {
		margin-bottom: 4em;
	}
	/* Copyright */
	#copyright {
		padding: 3em 0 4em;
	}
}
/* Mobile */
#navPanel,
#titleBar {
	display: none;
}
@include breakpoint(mobile) {
	/* Basic */
	body,
	html {
		overflow-x: hidden;
	}
	body {
		line-height: 1.75em;
		font-size: 9.5pt;
	}
	h2 {
		font-size: 1.5em;
		margin: 0 0 1.5em;
		letter-spacing: -1px;
	}
	article,
	section {
		clear: both;
		margin: 0 0 5em;
	}
	article,
	section {
		 &:last-child,
		> :last-child {
			margin-bottom: 0 !important;
		}
		header {
			border-top: solid 5px #7ABAA4;
			padding-top: 2em;
			margin: 0 0 2.5em;
			.byline {
				display: block;
				color: #a0a0a0;
				font-size: 1.25em;
				line-height: 1em;
			}
			h2 {
				margin: 0 0 0.5em !important;
			}
		}
		&.featured {
			header {
				.byline {
					font-size: 1.5em;
					letter-spacing: -1px;
				}
				h2 {
					font-size: 2em;
					letter-spacing: -2px;
					margin: 0.25em 0 0.75em;
					line-height: 1em;
				}
			}
		}
	}
	.align-left {
		margin-bottom: 1em;
	}
	.image-fit-mobileOnly {
		display: block;
		margin: 0 0 2em;
		width: 100%;
		img {
			width: 100%;
		}
	}
	.button {
		@include vendor( 'box-sizing', 'border-box');
		width: 100%;
		text-align: center;
		font-size: 1.25em;
		&:before {
			content: '';
			display: block;
			position: absolute;
			right: 1.75em;
			bottom: 1.6em;
			border-left: solid 5px #fff;
			border-top: solid 5px transparent;
			border-bottom: solid 5px transparent;
		}
	}
	.blocks {
		a {
			padding: 1.5em 6.3em 1.5em 1.3em;
			margin: 0 0 0.5em;
			&:after {
				right: 20px;
				bottom: 20px;
			}
		}
		p {
			font-size: 1em;
			margin: 0;
		}
		h2 {
			font-size: 2em;
			margin: 0 0 0.5em;
		}
	}
	/* Wrappers */
	#header-wrapper {
		display: none;
	}
	#search-wrapper {
		background: #f6f6f6;
		border-bottom: solid 1px #d9d9d9;
		padding: 1.5em 20px;
	}
	#main-wrapper {
		padding: 4em 20px;
	}
	#footer-wrapper {
		background: #f6f6f6;
		border-top: solid 1px #d9d9d9;
		padding: 4em 20px;
		section {
			margin-bottom: 5em !important;
		}
	}
	/* Off-Canvas Navigation */
	#page-wrapper {
		@include vendor( 'backface-visibility', 'hidden');
		@include vendor( 'transition', 'transform #{_duration(navPanel)} ease');
		padding-bottom: 1px;
		padding-top: 44px;
	}
	#titleBar {
		@include vendor( 'backface-visibility', 'hidden');
		@include vendor( 'transition', 'transform #{_duration(navPanel)} ease');
		display: block;
		height: 44px;
		left: 0;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: _misc(z-index-base) + 1;
		background: #7ABAA4;
		color: #fff;
		.title {
			display: block;
			text-align: center;
			font-size: 1.25em;
			letter-spacing: -1px;
			line-height: 44px;
			span {
				font-weight: 400;
			}
		}
		.toggle {
			position: absolute;
			text-indent: -9999px;
			left: 0;
			top: 0;
			width: 70px;
			height: 50px;
			&:before {
				content: '';
				display: block;
				position: absolute;
				background: rgba(0, 0, 0, 0.1) url('images/toggle.svg') 13px 10px no-repeat;
				width: 50px;
				height: 30px;
				top: 7px;
				left: 7px;
			}
			&:active:before {
				background-color: rgba(0, 0, 0, 0.3);
			}
		}
	}
	#navPanel {
		@include vendor( 'backface-visibility', 'hidden');
		@include vendor( 'transform', 'translateX(#{_size(navPanel) * -1})');
		@include vendor( 'transition', ('transform #{_duration(navPanel)} ease'));
		display: block;
		height: 100%;
		left: 0;
		overflow-y: auto;
		position: fixed;
		top: 0;
		width: _size(navPanel);
		z-index: _misc(z-index-base) + 2;
		background: #2f2f2f url('images/overlay.png');
		box-shadow: inset -3px 0 3px 0 #282828;
		.link {
			display: block;
			color: #aaa;
			text-decoration: none;
			border-top: solid 1px rgba(255, 255, 255, 0.1);
			height: 44px;
			line-height: 44px;
			padding: 0 1.5em;
			&:first-child {
				border-top: 0;
			}
		}
		.indent-1 {
			display: inline-block;
			width: 1em;
		}
		.indent-2 {
			display: inline-block;
			width: 2em;
		}
		.indent-3 {
			display: inline-block;
			width: 3em;
		}
		.indent-4 {
			display: inline-block;
			width: 4em;
		}
		.indent-5 {
			display: inline-block;
			width: 5em;
		}
		.depth-0 {
			color: #fff;
		}
	}
	body {
		&.navPanel-visible {
			#page-wrapper {
				@include vendor( 'transform', 'translateX(#{_size(navPanel)})');
			}
			#titleBar {
				@include vendor( 'transform', 'translateX(#{_size(navPanel)})');
			}
			#navPanel {
				@include vendor( 'transform', 'translateX(0)');
			}
		}
	}
	/* Banner */
	#banner {
		position: relative;
		width: 100%;
		.nav-next,
		.nav-previous {
			@include vendor( 'transition', 'background-color .25s ease-in-out');
			position: absolute;
			top: 0;
			width: 70px;
			height: 100%;
			z-index: 3;
			cursor: pointer;
			opacity: 0.75;
			&:before {
				font-family: FontAwesome;
				text-decoration: none;
				font-size: 72px;
				font-style: normal;
				font-weight: normal;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				color: #fff;
				display: block;
				width: 52px;
				height: 64px;
				line-height: 64px;
				position: absolute;
				text-align: center;
				top: 50%;
				left: 50%;
				margin: -32px 0 0 -26px;
			}
			&:hover {
				opacity: 1.0;
			}
		}
		.nav-next {
			right: 0;
			&:before {
				content: "\f105";
			}
		}
		.nav-previous {
			left: 0;
			&:before {
				content: "\f104";
			}
		}
		.captions {
			position: relative;
			background: #2f2f2f url('images/overlay.png');
			color: #fff;
			padding: 1.75em 20px;
			.caption-line-1 {
				font-size: 1.5em;
				letter-spacing: -1px;
				display: block;
				margin: 0 0 0.5em;
			}
			.caption-line-2 {
				font-size: 1.1em;
				color: rgba(255, 255, 255, 0.8);
			}
		}
		.viewer {
			background: #fff;
		}
		.slide {
			img {
				display: block;
				width: 100%;
			}
			.link {
				position: absolute;
				text-indent: -9999px;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
			}
		}
	}
	/* Search */
	#search {
		position: relative;
		span {
			display: none;
		}
		form {
			display: block;
			position: relative;
			input {
				&.text {
					display: block;
					width: 100%;
					height: 34px;
				}
				&.button {
					width: 34px;
					height: 34px;
					position: absolute;
					right: 0;
					top: 0;
					display: block;
					z-index: 1;
					border: 0;
					padding: 0;
					opacity: 0;
				}
			}
			&:before {
				content: "\f002";
				font-family: FontAwesome;
				text-decoration: none;
				font-size: 18px;
				font-style: normal;
				font-weight: normal;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 2;
				background: #7ABAA4;
				color: #fff;
				width: 34px;
				height: 34px;
				line-height: 34px;
				text-align: center;
			}
		}
	}
	/* Copyright */
	#copyright {
		border-top: solid 1px #d9d9d9;
		text-align: center;
		padding: 2.5em 0 0;
		color: #a0a0a0;
	}
}
// paginator
.center {
	text-align: center;
}
.pagination {
	display: inline-block;
	padding-left: 0;
	margin: 20px 0;
	border-radius: 4px;
}
.pagination > li {
	display: inline;
}
.pagination > li > a,
.pagination > li > span {
	position: relative;
	float: left;
	padding: 6px 12px;
	line-height: 1.42857143;
	text-decoration: none;
	color: #337ab7;
	background-color: #ffffff;
	border: 1px solid #dddddd;
	margin-left: -1px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
	margin-left: 0;
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;
}
.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
	z-index: 2;
	color: #23527c;
	background-color: #eeeeee;
	border-color: #dddddd;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
	z-index: 3;
	color: #ffffff;
	background-color: #337ab7;
	border-color: #337ab7;
	cursor: default;
}
.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
	color: #777777;
	background-color: #ffffff;
	border-color: #dddddd;
	cursor: not-allowed;
}
.pagination-lg > li > a,
.pagination-lg > li > span {
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
}
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
	border-bottom-left-radius: 6px;
	border-top-left-radius: 6px;
}
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
	border-bottom-right-radius: 6px;
	border-top-right-radius: 6px;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
}
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
}
